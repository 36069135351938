import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { RecaptchaSiteKey, SupportReson } from 'src/app/base/model/constants/AppConstants';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-user-contact-us',
  templateUrl: './user-contact-us.component.html',
})
export class UserContactUsComponent {
  constructor(private _baseService: BaseService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private translate: TranslateService,
    private customToastrService: CustomToastrServiceService
  ) { }
  userSupportForm: FormGroup;
  ticketResponse: string;
  selectedFile: { file: File, dataUrl: string }[] = [];
  picFileName: string[] = [];
  userEmail;
  recaptchaSiteKey = RecaptchaSiteKey.siteKey;
  getCulture: any;
  showCaptcha;
  isInvalidCaptcha: boolean;
  items: any;
  isShowCaptcha:boolean;
  resoanValue: boolean;
  @ViewChild(ReCaptcha2Component, { static: false }) captchaComponent: ReCaptcha2Component;
  ngOnInit() {
    this.items = Object.keys(SupportReson);
    this.userSupportForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', Validators.required),
      selectFile: new FormControl(''),
      textMessage: new FormControl('', Validators.required),
      queryType: new FormControl('', Validators.required),
      recaptcha: ['', Validators.required]
    })

    this.userService.getuserTotalPoint();
    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }

    this.userService.sendUserProfile.subscribe((res:any)=>{
      this.userEmail = res.email
    })
    
    this.userService.countryCultureSource.subscribe(country => {
      this.getCulture = country;
      this.isShowCaptcha = true      
    })
  }

  onSubmitrequest() {
    this.resoanValue = false;
    this.userService.markFormGroupTouched(this.userSupportForm);
    if (this.userSupportForm.get('recaptcha').invalid) {
      this.isInvalidCaptcha = true
      return;
    }

    if (this.userSupportForm.invalid) {
      return;
    }
    const recaptcha = this.userSupportForm.value.recaptcha;
    const reCaptchaTokenBody = {
      token: recaptcha
    }
    const fileData = new FormData();
    fileData.append('email', this.userSupportForm.value.email);
    fileData.append('queryType', this.userSupportForm.value.queryType);
    fileData.append('subject', this.userSupportForm.value.subject);
    fileData.append('v', recaptcha);
    for (let i = 0; i < this.selectedFile.length; i++) {
      fileData.append('image', this.selectedFile[i].file);
    }
    fileData.append('text', this.userSupportForm.value.textMessage);
    // this._baseService.post(userApi.verifyReCaptcha, reCaptchaTokenBody).then((res)=>{
      this._baseService.post(userApi.supportTicket, fileData).then((res: any) => {
        this.translate.get('supportMail').subscribe((translate: string) => {
          this.userSupportForm.get('subject').reset();
          this.userSupportForm.get('textMessage').reset();
          this.userSupportForm.get('recaptcha').reset();
        this.userSupportForm.get('queryType').setValue("");
        this.resoanValue = true;
          this.toastr.success(translate, '', {
            toastClass: 'success-toast',
            timeOut: 3000,
          })
          this.captchaComponent.reloadCaptcha();
        })
      }).catch((error)=>{
        this.captchaComponent.reloadCaptcha();
        this.toastr.error(error.message)
      })
    // }).catch((err)=>{
    //   this.isInvalidCaptcha = true;
    // })
    this.picFileName = [];
    this.selectedFile = [];
  }

  onFileSelected(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file:File = event.target.files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedFile.push({
            file: file,
            dataUrl: reader.result as string
        });
    };
    this.picFileName.push(file.name);
    }
  }

  cancelSelectedPhoto(index) {
    this.selectedFile.splice(index, 1);
    this.picFileName.splice(index, 1);
  }

  isImageType(fileType: string): boolean {
    return fileType.startsWith('image/');    // Check if the file type starts with 'image/'
  }

  handleSuccess(event){
    this.isInvalidCaptcha = false;
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  selectReson(event) {
    const value = event.target.value;
    const isSelectDefaultValue = this.userSupportForm.get('queryType').invalid
    if (!value && !isSelectDefaultValue) {
      this.resoanValue = true
    }
  }
}
