import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import confetti from 'canvas-confetti';
@Component({
  selector: 'app-callback-survey',
  templateUrl: './callback-survey.component.html',
})
export class CallbackSurveyComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private _baseService: BaseService,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private deviceDetector: DeviceDetectorService
  ) {
    this.epicFunction();
  }

  statusMessage: string = '';
  statusToken: string = '';
  userSurvey: any;
  isTokenExist: boolean = false;
  deviceInfo;
  surveyMessage;
  statusCode:number;
  confettiInterval:any;
  isShowCongratulation : boolean;
  isShowDefaultIco: boolean;
  ngOnInit() {
    const Uculture = localStorage.getItem('Uculture')
    if (Uculture) {
      this.translate.use(Uculture);
    }

    this.activeRoute.params.subscribe((param) => {
      const statusToken = param['token']
      this.statusCode = Number(param['id'])
      switch (this.statusCode) {
        case 0:
          this.handleAlreadyParticipate();
          break;
        case 7:
          this.handleIpTerminate();
          break;
        case 10:
            this.handleSurveyClose();
          break;
        case 17:
            this.handleCountryMissMatch();
          break;

        default:
          this.getUserSurveyParticipation(statusToken);
          return;
      }
      clearInterval(this.confettiInterval);
      confetti.reset();
      this.redirectUser();
    })
    this.isTokenExist = !!localStorage.getItem('access_token');
  }

  ngOnDestroy() {
    if (this.confettiInterval) {
      clearInterval(this.confettiInterval);
    }
  }

  getUserSurveyParticipation(statusToken: any) {
    this._baseService.get(userApi.getUserSurveyParticipation + `?token=${statusToken}`).then((res: any) => {
      this.userSurvey = res;
      if (res.hasOwnProperty('userStatus')) {
        this.setSurveyMessage(res);
      } else {
        this.translate.get('tryAnotherSurvey').subscribe((translate) => {
          this.surveyMessage = translate
        })
      }
      this.redirectUser();
    }).catch((error) => {
      this.statusCode = Math.random(); 
      this.translate.get('somethingWentWrong').subscribe((translate) => {
        this.surveyMessage = translate
      })
    })
  }

  setSurveyMessage(res: any) {
    const userStatus = res.userStatus;
    switch (userStatus) {
      case 2:
        this.statusCode = 2;
        this.handleSurveyComplete(res);
        this.launchConfetti();
        break;
      case 13:
        this.statusCode = 13;
        this.handleQuotaLimit();
        break;
      default:
        this.statusCode = Math.random(); 
        clearInterval(this.confettiInterval);
        confetti.reset();
        this.handleDefaultCase(res);
        break;
    }
  }

  handleSurveyComplete(res: any) {
    this.isShowCongratulation = true;
    this.translate.get('surveyCompleteMessage').subscribe((translate) => {
      const earned = this.translate.instant('youEarned');
      const points = this.translate.instant('points');
      this.surveyMessage = `${translate} ${res.points !== null ? `${earned} <span class="fw-bold text-primary"><div class="fs-3">${res.points} ${points}</div></span>` : ''}`;
    });
  }

  handleIpTerminate() {
    this.translate.get('ipTerminatMessage').subscribe((translate) => {
      this.surveyMessage = translate;
    });
  }

  handleSurveyClose() {
    this.translate.get('surveyCloseMessage').subscribe((translate) => {
      this.surveyMessage = translate;
    });
  }

  handleQuotaLimit() {
    this.statusCode = 13;
    this.translate.get('quotaLimitMessage').subscribe((translate) => {
      this.surveyMessage = translate;
    });
  }

  handleAlreadyParticipate() {
    this.translate.get('alreadyParticipateSurvey').subscribe((translate) => {
      console.log(this.translate.store.currentLang);
      this.surveyMessage = translate;
    });
  }

  handleCountryMissMatch() {
    this.translate.get('ipTerminatMessage').subscribe((translate) => {
      this.surveyMessage = translate;
    });
  }

  handleDefaultCase(res: any) {
    if (res?.points !== null && !isNaN(res.points)) {
      this.translate.get('profileCorrespondMessage').subscribe((translate) => {
        const points = this.translate.instant('points');
        this.surveyMessage = `${translate} ${res.points !== null ? `<span class="fw-bold text-primary">${res.points} ${points}</span>` : ''}`;
      });
    } else {
      this.translate.get('tryAnotherSurvey').subscribe((translate) => {
        this.surveyMessage = translate;
      });
    }
  }

  redirectUser() {
    setTimeout(() => {
      const destination = this.isTokenExist ? '/login/body/my-survey' : '/login';
      confetti.reset();
      this.router.navigate([destination]);
    }, 9000);
  }

  epicFunction() {
    this.deviceInfo = this.deviceDetector.getDeviceInfo();
  }

  launchConfetti(): void {
    confetti({
      particleCount: 500,
      spread: 360,
      origin: {
        x: 0.5,
        // since they fall down, start a bit higher than random
        y: 0.6
      }
    });
    setTimeout(() => {
      this.confettiInterval = setInterval(() => {
        confetti({
          particleCount: 500,
          spread: 360,
          origin: {
            x: 0.5,
            // since they fall down, start a bit higher than random
            y: 0.6
          }
        });
      },2500)
    }, 0);
  }
}
