<div class="container-fluid">
    <div class="row fixed-topheader">
        <div class="row sticky-top">
          <div class="d-flex justify-content-between dashboard-mainboady">
            <div class="dashboard-heading">
              <h3>{{'privacyPolicy'|translate}}</h3>
            </div>
            <div class="dashboard-headingicons">
              <app-header></app-header>
            </div>
          </div>
          <hr class="dashboardhr-line" />
        </div>
      </div>
      <div  class="row fixed-body">
        <div class="container-fluid">
            <div class="row px-3">
              <ul class="nav privacy-policy-tabs">
                <li class="nav-item" (click)="showPrivacyDiv()">
                  <a
                    [ngClass]="!showPrivacy?'nav-link text-secondary cursor-pointer':'nav-link active'"
                    aria-current="page">{{'privacyPolicy'|translate}}</a>
                </li>
                <li class="nav-item" (click)="showTermsDiv()">
                  <a 
                    [ngClass]="showPrivacy?'nav-link text-secondary cursor-pointer':'nav-link active'">{{'termsConditions'|translate}}</a>
                </li>
              </ul>
            </div>
            <div class="text-secondary px-3" *ngIf="showPrivacy">
              <div class="privacy-top">
                <h6>{{'applicableSince'|translate}} - 02-09-2021</h6>
                <h6 class=" m-0">{{'lastUpdated'|translate}} - 15-02-2023</h6>
              </div>
          
              <div class="privacy-text">
                <div class="card-body ">
                  <span class="m-0">
                    <h3 class="privacy-heading">
                      {{'privacyStatement'|translate}}
                    </h3>
                  </span>
                  <p class=" m-0">
                    {{'privacyPara1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'privacyPara2'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'privacyPara3'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h5 class="privacy-heading">
                      {{'privacyTitle1'|translate}}
                    </h5>
                  </span>
          
                  <p class=" m-0 mb-2">
                    {{'privacySubTitle1'|translate}}
                  </p>
          
          
                  <p class=" m-0">
                     &nbsp;{{'regulatesTitle1'|translate}}<br />
                     &nbsp;{{'regulatesTitle2'|translate}}<br />
                     &nbsp;{{'regulatesTitle3'|translate}}<br />
                     &nbsp;{{'regulatesTitle4'|translate}}<br />
                     &nbsp;{{'regulatesTitle5'|translate}}<br />
                     &nbsp;{{'regulatesTitle6'|translate}}<br />
                     &nbsp;{{'regulatesTitle7'|translate}} <br />
                     &nbsp;{{'regulatesTitle8'|translate}}<br />
                     &nbsp;{{'regulatesTitle9'|translate}}<br />
                     &nbsp;{{'regulatesTitle10'|translate}}<br />
                  </p>
          
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">
                      {{'privacyTitle2'|translate}}
                    </h6>
                  </span>
          
                  <p class=" m-0">
                    {{'personalDataTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'personalDataTitle2'|translate}}<br />
                    {{'personalDataTitle3'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">
                      {{'privacyTitle3'|translate}}
                    </h6>
                  </span>
          
                  <p class=" m-0 mb-2">
                    {{'lawfulCollectionTitle0'|translate}}
                  </p>
          
                  <p class=" m-0">
                  &nbsp;{{'lawfulCollectionTitle1'|translate}}<br />
                  &nbsp;{{'lawfulCollectionTitle2'|translate}}<br />
                  &nbsp;{{'lawfulCollectionTitle3'|translate}}<br />
                  &nbsp;{{'lawfulCollectionTitle4'|translate}}<br />
                  </p>
                  <br />
          
                  <span class="m-0">
                    <h6 class="privacy-heading">
                      {{'privacyTitle4'|translate}}
                    </h6>
                  </span>
          
                  <p class=" m-0">
                    {{'dataTransferProtocolsTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'dataTransferProtocolsTitle2'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'dataTransferProtocolsTitle3'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'dataTransferProtocolsTitle4'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'dataTransferProtocolsTitle5'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle5'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'securityTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'securityTitle2'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle6'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'accuracyTitle1'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle7'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'retentionTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'retentionTitle2'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'retentionTitle3'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle8'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'cookieTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'cookieTitle2'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'cookieTitle3'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle8'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'changePolicyTitle1'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h6 class="privacy-heading">{{'privacyTitle10'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'privacyLawsTitle1'|translate}}<br /><br />
                  </p>
          
                  <p class=" m-0">
                    &nbsp;{{'privacyLawsTitle2'|translate}} <br />
                    &nbsp;{{'privacyLawsTitle3'|translate}}<br />
                    &nbsp;{{'privacyLawsTitle4'|translate}}<br />
                    &nbsp;{{'privacyLawsTitle5'|translate}}<br />
                    &nbsp;{{'privacyLawsTitle6'|translate}}<br />
                    &nbsp;{{'privacyLawsTitle7'|translate}}
                  </p>
                  <br />
                  <span>
                    <h6 class="m-0 privacy-heading">{{'privacyTitle11'|translate}}</h6>
                  </span>
          
                  <p class=" m-0">
                    {{'commitmentTitle1'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'commitmentTitle2'|translate}}
                  </p>
                  <br />
                  <p class=" m-0">
                    {{'commitmentTitle3'|translate}}
                  </p>
                  <br />
                  <span class="m-0">
                    <h5 class="privacy-heading">{{'privacyTitle12'|translate}}</h5>
                  </span>
          
                  <p class=" m-0">
                    {{'contactTitle1'|translate}}
                  </p>
                  <br />
          
                </div>
              </div>
            </div>
            <div *ngIf="showTerms" class="user-terms-page">
              <app-terms-conditions></app-terms-conditions>
            </div>
          </div>
      </div>
</div>
