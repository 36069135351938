<header class="sticky-top">
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
      <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
          <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
        </a>
      </div>
      <hr />
    </nav>
  </header>
<div class="center-div mx-width-700 pt-3">
    <div class="unverified-email">
      <div class="row text-center">
        <div class="col-md-12">
             <img src="../../../../../assets/image/server-img.jpg" class="server-img" alt="server.img">
            <h4>Oops! Our Server is Currently Down</h4>
            <p>We apologize for the inconvenience. Our team is working hard to resolve the issue and get things back online as soon as possible. Thank you for your patience and understanding!</p>
            <p>  If you have any questions or need assistance, please contact us at <br> Support@opinion-edge.com.
            </p>
        </div>
      </div>
    </div>
  </div>

