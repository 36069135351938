import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/users/components/login/login.component';
import { PrivacyPolicyComponent } from './modules/base/components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './modules/base/components/terms-conditions/terms-conditions.component';
import { ContactUsComponent } from './modules/base/components/contact-us/contact-us.component';
import { CreateAccountComponent } from './modules/base/components/create-account/create-account.component';
import { DashboardComponent } from './modules/users/components/dashboard/dashboard.component';
import { MySurveyComponent } from './modules/users/components/my-survey/my-survey.component';
import { PersonalDetailComponent } from './modules/users/components/personal-detail/personal-detail.component';
import { PointDetailComponent } from './modules/users/components/point-detail/point-detail.component';
import { ProfileQuestionComponent } from './modules/users/components/profile-question/profile-question.component';
import { FoodComponent } from './modules/users/components/profile-question-category/food/food.component';
import { HealthComponent } from './modules/users/components/profile-question-category/health/health.component';
import { ProfileComponent } from './modules/users/components/profile-question-category/profile/profile.component';
import { ShoppingComponent } from './modules/users/components/profile-question-category/shopping/shopping.component';
import { TechnologyComponent } from './modules/users/components/profile-question-category/technology/technology.component';
import { RedeemPointComponent } from './modules/users/components/redeem-point/redeem-point.component';
import { ReferEarnComponent } from './modules/users/components/refer-earn/refer-earn.component';
import { LeaderboardComponent } from './modules/users/components/leaderboard/leaderboard.component';
import { BodyPageComponent } from './modules/base/components/body-page/body-page.component';
import { AutomobileComponent } from './modules/users/components/profile-question-category/automobile/automobile.component';
import { AuthenticationGuard } from './base/gaurd/authentication.guard';
import { CallbackSurveyComponent } from './modules/base/components/callback-survey/callback-survey.component';
import { ForgotPasswordComponent } from './modules/base/components/forgot-password/forgot-password.component';
import { EmailVerifyComponent } from './modules/base/components/email-verify/email-verify.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { UserPrivacyPolicyComponent } from './modules/users/components/user-privacy-policy/user-privacy-policy.component';
import { UserContactUsComponent } from './modules/users/components/user-contact-us/user-contact-us.component';
import { SurveyLandingComponent } from './modules/base/components/survey-landing/survey-landing.component';
import { UnsubscribeComponent } from './modules/base/components/unsubscribe/unsubscribe.component';
import { WelcomePageComponent } from './modules/base/components/welcome-page/welcome-page.component';
import { GdiComponent } from './modules/base/components/gdi/gdi.component';
import { AppInstallationComponent } from './modules/base/components/app-installation/app-installation.component';
import { FAQSComponent } from './modules/base/components/faqs/faqs.component';
import { UserFaqsComponent } from './modules/users/components/user-faqs/user-faqs.component';
import { ResolverService } from './modules/users/services/resolver.service';
import { AppServerComponent } from './modules/base/components/app-server/app-server.component';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent, children: [
      { path: 'terms-conditions', component: TermsConditionsComponent },
    ]
  },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'create-account', component: CreateAccountComponent },
  { path: 'create-account/:id', component: CreateAccountComponent },
  { path: 'create-account?affid=value1&tid=&campid=&scampid=', component: CreateAccountComponent },
  { path: 'join', component: CreateAccountComponent },
  {
    path: 'login/body', component: BodyPageComponent, canActivate: [AuthenticationGuard], children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
      { path: 'my-survey', component: MySurveyComponent, canActivate: [AuthenticationGuard] },
      { path: 'personal-detail', component: PersonalDetailComponent, canActivate: [AuthenticationGuard] },
      { path: 'point-detail', component: PointDetailComponent, canActivate: [AuthenticationGuard] },
      {
        path: 'profile-question', component: ProfileQuestionComponent, canActivate: [AuthenticationGuard], children: [
          { path: 'automobile', component: AutomobileComponent },
          { path: 'food', component: FoodComponent },
          { path: 'health', component: HealthComponent },
          { path: 'profile', component: ProfileComponent },
          { path: 'shopping', component: ShoppingComponent },
          { path: 'technology', component: TechnologyComponent },
        ]
      },
      { path: 'redeem-point', component: RedeemPointComponent, canActivate: [AuthenticationGuard] },
      { path: 'refer-earn', component: ReferEarnComponent, canActivate: [AuthenticationGuard] },
      { path: 'leaderboard', component: LeaderboardComponent, canActivate: [AuthenticationGuard] },
      { path: 'user-contact-us', component: UserContactUsComponent },
      { path: 'user-privacy-policy', component: UserPrivacyPolicyComponent},
      { path: 'user-faqs', component: UserFaqsComponent},
    ]
  },
  { path: 'callback-survey/:id/:token', component: CallbackSurveyComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'email-verify', component: EmailVerifyComponent},
  { path: 'landing-page', component: LandingPageComponent},
  { path: 'survey-landing', component: SurveyLandingComponent},
  { path: 'unsubscribe', component: UnsubscribeComponent},
  { path: 'gdi', component: GdiComponent},
  { path: 'welcome-page', component: WelcomePageComponent},
  { path: 'install-app', component: AppInstallationComponent},
  { path: 'faqs', component: FAQSComponent},
  { path: 'server', component: AppServerComponent},
  { path: '**', component: LoginComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
